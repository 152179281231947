<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { Head } from '@inertiajs/vue3'
import giftlyFavIcon from '@consumer/assets/images/favicon.ico'
import { useDefaultTheme } from '@consumer/composables/themes'
import ModalsManager from '@consumer/modules/shared/ModalsManager.vue'
import TheNavigationProgressBar from '@corp/components/TheNavigationProgressBar.vue'
import ToastsManager from '@consumer/modules/layout/ToastsManager.vue'
import UpdateProfileModal from '@consumer/modules/shared/UpdateProfileModal.vue'
import { useSharedData } from '@consumer/composables/sharedData'

defineOptions({
  inheritAttrs: false,
})

useDefaultTheme()

onMounted(() => {
  document.querySelector('.woot--bubble-holder')?.classList.add('no-percy')
})

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');
</script>

<template>
  <Head>
    <title>Giftly</title>
    <link rel="icon" :href="giftlyFavIcon">
  </Head>

  <main class="flex flex-col h-full" v-bind="$attrs">
    <slot v-bind="$attrs"/>
  </main>

  <ToastsManager/>
  <ModalsManager/>
  <UpdateProfileModal v-if="currentUser?.requestProfileUpdate"/>
  <TheNavigationProgressBar/>
</template>

<style lang="scss">
.fullscreen-mobile {
  @media screen and (max-width: 1023px) {
    .recipient-layout-container {
      @apply overflow-y-hidden flex flex-col fixed inset-0;
    }

    .recipient-layout-footer {
      @apply hidden;
    }
  }
}
</style>
